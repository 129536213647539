@import url("https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,600i,700,700i");

.welcome-area {
  background: #fff;
}
.hero-text {
  margin-top: 200px;
}
.hero-text h1 {
  color: #35ac39;
  font-size: 60px;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}
.hero-text h3 {
  margin-bottom: 60px;
  font-weight: 600;
  color: #333;
  position: relative;
}
.back-home {
  background: #35ac39;
  border: 2px solid #35ac39;
  border-radius: 500px;
  color: #fff;
  font-weight: 600;
  position: relative;
  margin: 15px 15px 0;
  padding: 12px 36px;
  text-transform: capitalize;
  transition: all 0.2s ease 0s;
  text-decoration: navajowhite;
}
.back-home:hover,
.back-home:focus {
  background: #4328b7;
  color: #fff;
  border: 2px solid #4328b7;
  text-decoration: none;
}
