.social-explore:hover > ul {
  display: flex;
}
.social-explore > ul {
  display: none;
}
.social-explore:hover > button {
  display: none;
}

.animated-element {
  animation: fadeIn 1s ease-in-out; /* Use the fadeIn animation for 1 second */
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
